.leftbar {
    background-color: #ffffffd6;
    position: relative;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @media only screen and (max-width:991px) {
        position: absolute;
        padding: 0px;
    }

    .logo img {
        margin: 30px auto 30px auto;
        display: block;
    }

    .menu {
        font-size: 1.5rem;
        margin: 0 0 15px 25px;
        cursor: pointer;

        @media only screen and (min-width:992px) {
            display: none;
        }
    }

    ul.navigation {
        @media only screen and (max-width:1124px) {
            padding-left: 15px;
        }

        @media only screen and (max-width:991px) {
            padding-left: 25px;
        }

        li {
            list-style-type: none;
            height: 60px;
            padding: 17px 10px;

            @media only screen and (max-width: 1024px) {
                height: 26px;
                font-size: 10pt;
                padding: 0px 2px;
            }
        }

        li:last-child {
            position: absolute;
            bottom: 20px;

            @media only screen and (max-width: 1024px) {
                position: relative;
                bottom: unset;
            }
        }

        li i {
            transform: rotate(180deg);
            margin-right: 10px;
            color: #a5c7a9;
        }

        li a {
            color: #404447;
            text-decoration: none;
            font-weight: 500;

            &:hover {
                color: #a5c7a9;
            }

            cursor: pointer;
        }
    }

    ul.navigation.responsive {
        position: absolute;
        z-index: 10;
        background-color: #ffffff;
        width: 100%;
        box-shadow: 0 5px 5px 0 #8aaf8e;

    }

    ul.navigation.responsive.hide {
        display: none;
    }

}