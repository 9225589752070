.login_container {
    padding: 0px 25px;

    .logo_login {
        max-width: 1400px;
        margin: 0 auto;

        @media only screen and (max-width: 728px) {
            margin: 0 25px;
        }
    }

    .copyright_login {
        max-width: 1400px;
        margin: 0 auto;
        text-align: right;
        color: #708072;
        font-size: 9pt;

        @media only screen and (max-width: 728px) {
            margin: 0 25px;
        }
    }

    .content_login {
        max-width: 1035px;
        border-radius: 5px;
        min-height: 625px;
        display: flex;
        margin: 3% auto;

        @media only screen and (max-width: 728px) {
            margin: 3% 25px;
        }

        .image {
            background-image: url(../assets/img/login_bg.png);
            display: inline-flex;
            width: 50%;
            background-position: center;
            background-size: cover;

            @media only screen and (max-width: 728px) {
                display: none;
            }
        }

        .textbox {
            background-color: #d7e5d7c7;
            display: inline-flex;
            width: 50%;
            padding: 50px 5%;

            @media only screen and (max-width: 728px) {
                width: 100%;
            }

            .text {
                h1 {
                    text-transform: uppercase;
                    color: #708072;
                    font-weight: 700;

                }

                p {
                    color: #708072;
                    font-size: 9pt;
                    margin-bottom: 100px;
                }

                a {
                    color: #708072;
                    font-size: 9pt;
                    margin-top: 50px;
                    float: left;
                    width: 50%;
                }

                input {
                    width: 100%;
                    color: #708072;
                    font-size: 10pt;
                    background-color: transparent;
                    border: 0;
                    border-bottom: 1px solid #a7c7ab;
                    font-style: italic;
                }

                button {
                    border: 0;
                    color: #fff;
                    margin-top: 20px;
                    width: 100%;
                    height: 51px;
                    background-size: cover;
                    background-position: center;
                    background-image: url(../assets/img/loginbutton.png);
                }

                .password {
                    margin-top: 30px;
                    margin-bottom: 50px;
                }

                .loginbutton {
                    border: 0;
                    color: #fff;
                    margin-top: 20px;
                    float: right;
                    width: 100%;
                    border-radius: 5px;
                    height: 50px;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 1px;
                    background-size: cover;
                    background-position: right;
                    background-image: url(../assets/img/loginbutton.png);
                }
            }
        }
    }
}



.content_newpassword {
    max-width: 1035px;
    border-radius: 5px;
    min-height: 625px;
    display: flex;
    margin: 3% auto;

    @media only screen and (max-width: 728px) {
        margin: 3% 25px;
    }

    .image {
        background-image: url(../assets/img/login_bg.png);
        display: inline-flex;
        width: 50%;
        background-position: center;
        background-size: cover;

        @media only screen and (max-width: 728px) {
            display: none;
        }
    }

    .textbox {
        background-color: #d7e5d7c7;
        display: inline-flex;
        width: 50%;
        padding: 50px 5%;

        @media only screen and (max-width: 728px) {
            width: 100%;
        }

        .text {
            h1 {
                text-transform: uppercase;
                color: #708072;
                font-weight: 700;

            }

            p {
                color: #708072;
                font-size: 9pt;
                margin-bottom: 100px;
            }

            a {
                color: #708072;
                font-size: 9pt;
                margin-top: 50px;
                float: left;
                width: 50%;
            }

            input {
                width: 100%;
                color: #708072;
                font-size: 10pt;
                background-color: transparent;
                border: 0;
                border-bottom: 1px solid #a7c7ab;
                font-style: italic;
            }

            button {
                border: 0;
                color: #fff;
                margin-top: 20px;
                width: 100%;
                height: 51px;
                background-size: cover;
                background-position: center;
                background-image: url(../assets/img/loginbutton.png);
            }

            .newpassword {
                margin-top: 30px;
                margin-bottom: 50px;
            }

            .loginbutton {
                border: 0;
                color: #fff;
                margin-top: 20px;
                float: right;
                width: 100%;
                border-radius: 5px;
                height: 50px;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1px;
                background-size: cover;
                background-position: right;
                background-image: url(../assets/img/loginbutton.png);
            }
        }
    }
}