.shortlink{
    background-image: url(../assets/img/short_btn.png);
    height: 69px;
    background-position: center;
    background-size: cover;
    border-radius: 3px;
    line-height: 70px;
    text-align: center;
    font-size: 10pt;
    color: #fff;
    margin-bottom: 20px;
    cursor: pointer;

    a {
        color: #fff;
        text-decoration: none;
        &:hover{
            color:#fff;
        }
    }
}