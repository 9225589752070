@import '~semantic-ui-css/semantic.min.css';

.ui.selection.dropdown {
    border: none !important;
    border-radius: 3px;
    background-color: #ffffffa1;
    padding: 19px;
    margin-top: 5px;
    .text {
        font-size: 10pt;
        font-weight: 500;
        color: #404447;
        font-family: "Poppins", sans-serif !important;
    }
    .dropdown.icon {
        padding: 19px
    }
}

.ui.multiple.dropdown > .label {
    font-size: 1rem;
    font-weight: 600;
    background-color: #a5c7a9;
}