.workoutcard{
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.719);
    margin-bottom: 20px;
    position: relative;
    img{
        margin: 10px auto;
        display:block;
        height: 40px;
        @media only screen and (max-width: 1024px) {
            margin: 10px 10px;
        }
    }
    h6{
        padding-top: 20px;
        line-height: 5px;
        @media only screen and (max-width: 1024px) {
            font-size: 11pt;
        }
    }
    .headline {
        font-size: 1.1rem;
        font-weight: 500;
    }
    p{
        font-size: 10pt;
        color: #a5c7a9;
        margin-bottom: 0;
        
    }
    .video_text{
        font-size: 10pt;
        color: #a5c7a9;
        padding: 21px 0;
        @media only screen and (max-width: 1024px) {
            padding: 21px 10px;
        }
    }
    .edit_delete{
        padding: 18px 10px;
        text-align:right;
        i{
            color:#a5c7a9;
            margin: 4px;
        }
    }
}

.workout_add_card{
    .video_preview{
        position: relative;
    }
    .videobtn{
        position: absolute;
        width: 76px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 40%;
    }
    p{
        font-size: 10pt;
        color: #a5c7a9;
        margin-bottom: 0;
        font-weight: 600;
        margin-top: 25px;
    }
    input{
        width: 100%;
        font-size: 10pt;
        border: 0;
        font-weight: 500;
        background-color: #ffffffa1;
        padding: 17px;
        margin-top: 5px;
        color: #404447;
        border-radius: 3px;
    }
    img{
        width: 100%;
        margin-top: 5px;
        margin-bottom: 30px;
        max-height: 180px;
        object-fit: contain;
    }
    img.add {
        cursor: pointer;
    }
    video{
        width: 100%;
        margin-top: 5px;
        margin-bottom: 30px;
        height: 120px;
        object-fit: cover;
    }
    textarea {
        width: 100%;
        font-size: 10pt;
        border: 0;
        font-weight: 500;
        background-color: #ffffffa1;
        padding: 17px;
        margin-top: 5px;
        color: #404447;
        border-radius: 3px;

        &:focus {
            outline: none;
        }
    }
    .ck-editor__editable_inline {
        min-height: 260px;
    }
    .ck-editor__editable{
        background-color: #ffffffab;
        border: none;
        p{
            font-size: 10pt;
            color: #141414;
            margin-bottom: 0;
            font-weight: 400;
            margin-top: 25px;
        }
    }
    .ck-toolbar{
        border: none;
        background-color: #ffffff40;
    }
}

.workout-detail-card {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.719);
    margin-bottom: 20px;
    padding: 0 25px;
    position: relative;

    .header {
        display: flex;
        flex-direction: row;

    }

    h3 {
        padding: 20px 0;
        margin: 0;
        color: #a5c7a9;
    }

    .custom-row {
        display: flex;
        flex-direction: row;
        flex: 1;
    }

    img {
        height: 120px;
        margin: 0 20px 20px 0;
        object-fit: cover;
    }

    video {
        height: 120px;
        margin: 0 20px 20px 0;
        object-fit: contain;
    }

    span {
        font-size: 1rem;
    }

    .info {
        padding-bottom: 10px;
        font-size: 1rem;
        font-weight: 500;

        span {
            color: #a5c7a9;
        }

        img {
            width: 20px;
            height: 20px;
            margin: 0 8px 0 0;
        }
    }

    p {
        margin-top: 16px;
        color: #a5c7a9;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .edit_delete{
        position: absolute;
        bottom: 10px;
        right:15px;
        i{
            color:#a5c7a9;
            margin: 4px;
        }
    }
}