.challenge-detail-card {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.719);
    margin-bottom: 20px;
    padding: 0 25px;
    position: relative;

    .header {
        display: flex;
        flex-direction: row;

    }

    h3 {
        padding: 20px 0;
        margin: 0;
        color: #a5c7a9;
    }

    h4 {
        padding: 20px 0;
        margin: 0;
        color: #a5c7a9;
    }

    h5 {
        margin: 0;
        margin-bottom: 15px;
    }

    .custom-row {
        display: flex;
        flex-direction: row;
        flex: 1;
        padding-bottom: 10px;
    }

    img {
        width: 150px;
        height: 150px;
        margin: 5px 20px 0 0;
        object-fit: contain;
    }

    img.lg {
        width: auto;
        height: 250px;
    }

    span {
        font-size: 1rem;
    }

    .text {
        padding-bottom: 20px;
        font-size: 1rem;
        line-height: 1.6rem;
        max-width: 500px;

        p.accentuation {
            color: #a5c7a9;
            font-weight: 500;
            padding-bottom: 5px;
        }
    }

    .list {
        font-size: 10pt;
        font-weight: 500;
        line-height: 1.2rem;
        display: block;
    }

    .info {
        padding: 5px;
        font-size: 1rem;
        font-weight: 500;

        span {
            color: #a5c7a9;
        }

        img {
            width: 20px;
            height: 20px;
            margin: 0 8px 0 0;
        }
    }

    p {
        margin-top: 16px;
        color: #a5c7a9;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .edit_delete {
        position: absolute;
        bottom: 10px;
        right: 15px;

        i {
            color: #a5c7a9;
            margin: 4px;
        }
    }

    th {
        font-size: 1rem;
        font-weight: 600;
    }

    td {
        font-size: 1rem;
        font-weight: 400;
    }

    table.table.table-striped {
        --bs-table-striped-bg: #a5c7a933;
    }

    .table-button {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}