.usercard{
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.719);
    padding-left: 70px;
    margin-bottom: 20px;
    position: relative;
    img{
        position: absolute;
        left: 15px;
        top: 15px;
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 25px;
    }
    .img-placeholder {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 50px;
        height: 50px;
    }
    .userlines p{
        font-size: 10pt;
        margin: 0;
        line-height: 18px;
        color: #8d99a3;
        letter-spacing: 1px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 10px;

        &:first-child{
            font-size: 12pt;
            font-weight: 600;
            line-height: 30px;
            padding-top: 8px;
            color: #404447;
         

        }
        &:last-child{
            padding-bottom: 50px;
        }
    }
    .edit_delete{
        position: absolute;
        bottom: 10px;
        right:15px;
        i{
            color:#a5c7a9;
            margin: 4px;
        }
    }
}

.kunden_add_card{
    p{
        font-size: 10pt;
        color: #a5c7a9;
        margin-bottom: 0;
        font-weight: 600;
        margin-top: 25px;
    }
    input{
        width: 100%;
        font-size: 10pt;
        border: 0;
        font-weight: 500;
        background-color: #ffffffa1;
        padding: 17px;
        margin-top: 5px;
        color: #404447;
        border-radius: 3px;
        &:focus{
            outline: none;
        }
    }
    textarea{
        width: 100%;
        font-size: 10pt;
        border: 0;
        font-weight: 500;
        background-color: #ffffffa1;
        padding: 17px;
        margin-top: 5px;
        color: #404447;
        border-radius: 3px;
        &:focus{
            outline: none;
        }
    }
    img{
        width: 100%;
        margin-top: 5px;
        margin-bottom: 30px;
        max-height: 180px;
        object-fit: contain;
    }
    .ck-editor__editable_inline {
        min-height: 260px;
    }
    .ck-editor__editable{
        background-color: #ffffffab;
        border: none;
        p{
            font-size: 10pt;
            color: #141414;
            margin-bottom: 0;
            font-weight: 400;
            margin-top: 25px;
        }
    }
    .ck-toolbar{
        border: none;
        background-color: #ffffff40;
    }
}