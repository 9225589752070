@import "navigation.scss";
@import "header.scss";
@import "dashboard.scss";
@import "theme.scss";
@import "customers.scss";
@import "workout.scss";
@import "news.scss";
@import "recipes.scss";
@import "login.scss";
@import "challenge.scss";
@import "semantic-ui.scss";
@import "profile.scss";
@import "toastr";

html {
    height: 100%;
}

body {
    font-family: "Poppins", sans-serif !important;
    font-size: 12pt;
    padding: 50px 0;
    color: #404447;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../assets/img/bg.jpg);
    background-color: #a5c7a9;
    background-attachment: fixed;

    @media only screen and (max-width:1124px) {
        padding: 0;
    }

    @media only screen and (max-width: 728px) {
        background-image: none;
    }
}

/* More Space
.row>*{
    padding-right: calc(var(--bs-gutter-x) * .8);
    padding-left: calc(var(--bs-gutter-x) * .8);

}
*/

:focus {
    outline: none;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #8aaf8e;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #8aaf8e;
}

.content {
    max-width: 1460px;
    margin: 0 auto !important;
    -webkit-box-shadow: 0 0 20px 0 #0000000f;
    box-shadow: 0 0 20px 0 #0000000f;
    border-radius: 5px;
    min-height: 825px;

    .rightbar {
        background-color: #ffffff7e;
        position: relative;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        @media only screen and (max-width:991px) {
            margin-top: 135px;
        }
    }
}

.main {
    margin: 10px 10px;
}

.create {
    margin: 10px 10px 20px 10px;

    button {
        background-image: url(../assets/img/button.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 186px;
        width: 100%;
        border-radius: 3px;
        height: 58px;
        color: #fff;
        border: none;
        font-size: 10pt;
        margin-top: 10px;
        margin-right: 10px;

        @media only screen and (max-width: 728px) {
            width: 100%;
        }
    }

    .danger {
        background-color: #cd5050;
        background-image: none;
    }

    input {
        font-size: 9pt;
        border: 0;
        font-weight: 500;
        font-style: italic;
        background-color: #ffffffa1;
        padding: 7px;
        margin: 25px 0px 0px 20px;
        color: #404447;
        border-radius: 3px;

        @media only screen and (max-width: 728px) {
            width: 100%;
        }
    }

    .fitjo-dropdown {
        height: 55px;
    }
}

/* .fitjo-dropdown {
    width: 100%;
    font-size: 10pt;
    border: 0;
    font-weight: 500;
    background-color: #ffffffa1;
    padding: 17px;
    margin-top: 5px;
    color: #404447;
    border-radius: 3px;
} */

input {
    font-family: "Poppins", sans-serif !important;
    line-height: 1em;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
  }

.fitjo-button {
    max-width: 186px;
    width: 100%;
    border-radius: 3px;
    height: 58px;
    border: none;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
        font-size: 10pt;
        color: #fff;
    }
}

.fitjo-button.primary {
    background-image: url(../assets/img/button.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fitjo-button.danger {
    background-color: #cd5050;
}

.box-justify-center {
    display: flex;
    flex: 1;
    justify-content: center;
}

.box-align-center {
    display: flex;
    flex: 1;
    align-items: center;
}

.paging {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    i {
        padding: 4px;
        cursor: pointer;
    }

    span {
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding: 6px 4px 4px 4px;
    }

    span.active {
        text-decoration: underline;
    }
}