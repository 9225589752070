.header{
    border-bottom: 1px solid #c7c7c7;
    margin: 10px 10px;
    padding: 5px 0;
    .row{
        height: 44px;
        p{
            padding: 10px 0;
            margin: 0;
            font-weight: 500;
        }
        .rightalign{
            text-align: right;
        }
        i{
            color: #9b9a9a;
            margin-right: 25px;
        }
        .rightcolumn{
            display: flex;
            flex: 1;
            justify-content: right;
            align-items: center;

            img {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                cursor: pointer;
            }
        }
    }
}