.rezeptcard{
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.719);
    margin-bottom: 20px;
    padding: 0 25px;
    position: relative;
    h6{
        padding: 20px 0;
        margin: 0;
    }
    .text p{
        font-size: 10pt;
        margin: 0;
        color: #8d99a3;
        &:first-child{
            color: #a5c7a9;
            font-weight: 500;
            padding-bottom: 5px;
        }
        &:last-child{
            padding-bottom: 50px;
        }
    }
    img{
        display: block;
        margin: 0 auto 31px auto;
        width: 100%;
    }
    .edit_delete{
        position: absolute;
        bottom: 10px;
        right:15px;
        i{
            color:#a5c7a9;
            margin: 4px;
        }
    }
}

.rezept_add_card{
    p{
        font-size: 10pt;
        color: #a5c7a9;
        margin-bottom: 0;
        font-weight: 600;
        margin-top: 25px;
    }
    input{
        width: 100%;
        font-size: 10pt;
        border: 0;
        font-weight: 500;
        background-color: #ffffffa1;
        padding: 17px;
        margin-top: 5px;
        color: #404447;
        border-radius: 3px;
    }
    img{
        width: 100%;
        margin-top: 5px;
        margin-bottom: 30px;
        max-height: 180px;
        object-fit: contain;
    }
    .ck-editor__editable_inline {
        min-height: 260px;
    }
    .ck-editor__editable{
        background-color: #ffffffab;
        border: none;
        p{
            font-size: 10pt;
            color: #141414;
            margin-bottom: 0;
            font-weight: 400;
            margin-top: 25px;
        }
    }
    .ck-toolbar{
        border: none;
        background-color: #ffffff40;
    }
}