.profile {

    p {
        font-size: 10pt;
        margin-bottom: 0;
        font-weight: 500;
        margin-bottom: 5px;
        &:first-child{
            color: #a5c7a9;
            font-weight: 600;
            margin-top: 25px;
        }
    }

    img {
        height: 160px;
        width: 160px;
        object-fit: cover;
    }
}