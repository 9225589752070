.newscard {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.719);
    padding: 20px 25px;
    position: relative;
    margin-bottom: 20px;

    h6 {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: break-spaces;
        overflow: hidden;
        height: 51px;
    }

    h6.discountHeadline {
        -webkit-line-clamp: 2;
        height: 34px;
    }

    .text {
        padding-bottom: 50px;

        p {
            font-size: 10pt;
            margin: 0;
            color: #8d99a3;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-child {
                padding-bottom: 50px;
            }
        }

        p.accentuation {
            color: #a5c7a9;
            font-weight: 500;
            padding-bottom: 5px;
        }

        span {
            white-space: pre-line;
            font-size: 10pt;
            margin: 0;
            color: #8d99a3;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 20;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 457px;
        }

        span.discountText {
            -webkit-line-clamp: 5;
            height: 115px;
        }
    }

    img {
        display: block;
        margin: 0 auto 15px auto;
        width: 100%;
    }

    .edit_delete {
        position: absolute;
        bottom: 10px;
        right: 15px;

        i {
            color: #a5c7a9;
            margin: 4px;
        }
    }
}

.news_add_card {

    .info {
        font-size: 10pt;
        color: #a5c7a9;
        font-weight: 600;
    }

    p {
        font-size: 10pt;
        color: #a5c7a9;
        margin-bottom: 0;
        font-weight: 600;
        margin-top: 25px;
    }

    input {
        width: 100%;
        font-size: 10pt;
        border: 0;
        font-weight: 500;
        background-color: #ffffffa1;
        padding: 17px;
        margin-top: 5px;
        color: #404447;
        border-radius: 3px;
    }

    input.sm {
        padding: 8px;
    }

    /*     select{
        width: 100%;
        font-size: 10pt;
        border: 0;
        font-weight: 500;
        background-color: #ffffffa1;
        padding: 17px;
        margin-top: 5px;
        color: #404447;
        border-radius: 3px;
    } */
    textarea {
        width: 100%;
        font-size: 10pt;
        border: 0;
        font-weight: 500;
        background-color: #ffffffa1;
        padding: 17px;
        margin-top: 5px;
        color: #404447;
        border-radius: 3px;

        &:focus {
            outline: none;
        }
    }

    textarea.sm {
        padding: 8px;
    }

    img {
        width: 100%;
        margin-top: 5px;
        max-height: 180px;
        object-fit: contain;
    }

    img.add {
        cursor: pointer;
    }

    img.cover {
        object-fit: cover;
        height: 38px;
        border-radius: 3px;
    }

    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #a5c7a9;
        cursor: pointer;
    }

    .icon-container.lg {
        height: 38px;
        width: 38px;
        border-radius: 4px;
        margin-top: 5px;

        img {
            width: 19px;
            height: 19px;
            margin-top: 0px;
            object-fit: contain;
        }
    }
    
    .icon-container.sm {
        height: 20px;
        width: 20px;
        border-radius: 2px;
        margin-right: 3px;

        img {
            width: 10px;
            height: 10px;
            margin-top: 0px;
            object-fit: contain;
        }
    }

    .list {
        font-size: 10pt;
        font-weight: 500;
        line-height: 1.2rem;
        display: block;
    }

    .ck-editor__editable_inline {
        min-height: 260px;
    }

    .ck-editor__editable {
        background-color: #ffffffab;
        border: none;

        p {
            font-size: 10pt;
            color: #141414;
            margin-bottom: 0;
            font-weight: 400;
            margin-top: 25px;
        }
    }

    .ck-toolbar {
        border: none;
        background-color: #ffffff40;
    }
}